export class Validator {
    constructor(options) {
        this.form = options.form;
        this.requiredInputsSelector = options.requiredInputSelector ?? '.input--required';
        this.requiredInputs = this.form?.querySelectorAll(this.requiredInputsSelector);
        this.phoneNumberInputs = this.form?.querySelectorAll('input[type="tel"]');
        this.phoneRegExp = /^\+7 \([\d]{3}\) [\d]{3}-[\d]{2}-[\d]{2}$/;
        this.emailRegExp = /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/;
        this.emailInputs = this.form?.querySelectorAll('input[type="email"]');
        this.errorElements = document.querySelectorAll('[data-input-id]');
        this.hasErrors = false;
    }

    validate() {
        this.cleanErrors();
        this.checkPhoneNumberInputs();
        this.checkEmailInputs();
        this.checkRequiredInputs();
        return !this.hasErrors;
    }

    checkRequiredInputs() {
        this.requiredInputs.forEach((input) => {
            if (!input.value) {
                this.hasErrors = true;
                this.showError(input, 'Заполните обязательное поле');
            }
        });
    }

    checkPhoneNumberInputs() {
        this.phoneNumberInputs.forEach((input) => {
            if (!input.value || this.phoneRegExp.test(input.value)) {
                return;
            }
            this.hasErrors = true;
            this.showError(input, 'Неправильный формат номера телефона');
        });
    }

    checkEmailInputs() {
        this.emailInputs.forEach((input) => {
            if (!input.value || this.emailRegExp.test(input.value)) {
                return;
            }
            this.hasErrors = true;
            this.showError(input, 'Неправильный формат email');
        });
    }

    cleanErrors() {
        this.hasErrors = false;
        this.errorElements.forEach((element) => {
            element.textContent = '';
        });
    }

    showError(input, text) {
        const errorElement = document.querySelector(`[data-input-id="${input.id}"]`);
        if (!errorElement) {
            return;
        }
        errorElement.textContent = text;
    }
}
