// TODO: разделить по методам
/* global ymaps3:writable */
export class ContactsMap {
    constructor(selector, options, markerOptions) {
        this.mapElement = document.querySelector(selector);
        this.options = options;
        this.markerOptions = markerOptions;
        this.init();
    }

    async init() {
        await ymaps3.ready;
        const {YMap, YMapDefaultSchemeLayer, YMapMarker, YMapDefaultFeaturesLayer, YMapControls} = ymaps3;
        const {YMapZoomControl} = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');
        this.map = new YMap(this.mapElement, this.options);

        const markerElement = document.createElement('img');
        markerElement.className = this.markerOptions.className;
        markerElement.src = this.markerOptions.src;
        this.map.addChild(new YMapDefaultSchemeLayer());
        this.map.addChild(new YMapDefaultFeaturesLayer());

        this.marker = new YMapMarker(
            {
                coordinates: this.markerOptions.coordinates,
            },
            markerElement
        );
        this.map.addChild(this.marker);

        const controls = new YMapControls({position: 'right'});
        controls.addChild(new YMapZoomControl());

        this.map.addChild(controls);
        window.addEventListener('resize', this.onWindowResize.bind(this));
        this.onWindowResize();
    }

    onWindowResize() {
        if (window.innerWidth > 879) {
            return;
        }
        this.map.setLocation({
            center: this.markerOptions.coordinates,
        });
    }
}
