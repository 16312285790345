import IMask from 'imask';

export class PhoneNumberMask {
    constructor() {
        this.elements = document.querySelectorAll('input[type="tel"]');
        this.elements.forEach((element) => {
            IMask(element, {
                mask: '+{7} (000) 000-00-00',
            });
        });
    }
}
