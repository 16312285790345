/* global BX:writable */

export class Search {
    constructor(options) {
        this.apiUrl = options.apiUrl;
        this.searchResultElementSelector = options.selectors.searchResultElement;
        this.searchResultElement = document.querySelector(this.searchResultElementSelector);
        this.node = document.querySelector(options.selectors.node);
        this.openers = document.querySelectorAll(options.selectors.opener);
        this.closers = document.querySelectorAll(options.selectors.closer);
        this.input = document.querySelector(options.selectors.input);
        this.overlay = document.querySelector(options.selectors.overlay);
        this.timer = null;
        this.DOMParser = new DOMParser();
        this.init();
    }

    init() {
        this.openers.forEach((opener) => {
            opener.addEventListener('click', () => {
                this.open();
            });
        });
        this.closers.forEach((closer) => {
            closer.addEventListener('click', () => {
                this.close();
            });
        });
        this.node.addEventListener('click', (evt) => {
            if (this.searchResultElement.innerHTML !== '' || evt.target.closest('.header') || !this.isOpen) {
                return;
            }
            this.close();
        });

        this.boundOnSearchInput = this.onSearchInput.bind(this);
        this.boundReplaceContent = this.replaceContent.bind(this);

        this.input.addEventListener('input', this.boundOnSearchInput);
    }

    open() {
        if (this.isOpen) {
            return this;
        }
        this.node.classList.add('is-open');
        this.node.setAttribute('aria-hidden', 'false');
        document.documentElement.classList.add('scroll-lock');
        this.overlay.classList.add('active');
        this.isOpen = true;
        return this;
    }

    close() {
        if (!this.isOpen) {
            return this;
        }
        this.node.classList.remove('is-open');
        this.node.setAttribute('aria-hidden', 'true');
        document.documentElement.classList.remove('scroll-lock');
        this.overlay.classList.remove('active');
        this.isOpen = false;
        this.reset();
        return this;
    }

    onSearchInput() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.sendRequest().then(this.boundReplaceContent);
        }, 500);
    }

    async sendRequest() {
        const res = await fetch(this.apiUrl + '?q=' + this.input.value, {
            headers: {
                'Session-Id': BX.bitrix_sessid(),
            },
        });
        return await res.text();
    }

    replaceContent(data) {
        const dom = this.DOMParser.parseFromString(data, 'text/html');
        const result = dom.querySelector(this.searchResultElementSelector)?.innerHTML ?? '';
        this.overlay.classList.toggle('white', !!result);
        this.searchResultElement.innerHTML = dom.querySelector(this.searchResultElementSelector)?.innerHTML ?? '';
    }

    reset() {
        this.overlay.classList.remove('white');
        this.searchResultElement.innerHTML = '';
        this.input.value = '';
    }
}
