/* global BX:writable */

import {Validator} from './Validator';
import {Fancybox} from '@fancyapps/ui';

export class Form {
    constructor(options) {
        this.el = document.querySelector(options.selectors.form);
        this.apiUrl = options.apiUrl ?? this.el.getAttribute('action');
        this.method = options.method ?? this.el.getAttribute('method');

        this.validator = new Validator({
            form: this.el,
        });

        this.result = document.querySelector(options.selectors.result);
        this.resultTitle = this.result?.querySelector(options.selectors.resultTitle);
        this.resultText = this.result?.querySelector(options.selectors.resultText);

        this.init();
    }

    init() {
        this.boundOnSubmit = this.onSubmit.bind(this);
        this.boundShowResult = this.showResult.bind(this);
        this.el.addEventListener('submit', this.boundOnSubmit);
    }

    onSubmit(evt) {
        evt.preventDefault();
        if (!this.validator.validate()) {
            return;
        }
        const formData = new FormData(this.el);
        this.data = JSON.stringify(Object.fromEntries(formData));
        this.send(this.data).then(this.boundShowResult).catch();
    }

    async send(data) {
        const res = await fetch(this.apiUrl, {
            method: this.method,
            body: data,
            headers: {
                'Content-Type': 'application/json',
                'Session-Id': BX.bitrix_sessid(),
            },
        });
        return await res.text();
    }

    showResult(data) {
        try {
            data = JSON.parse(data);
            this.setResultTitle(data.message);
            this.setResultText(data.text);
        } catch (e) {
            this.setResultTitle('Ошибка');
            this.setResultText('Произошла ошибка. Попробуйте обновить страницу и отправить заявку заново');
        }
        this.el.reset();
        Fancybox.close();
        new Fancybox(
            [
                {
                    src: this.result,
                    type: 'html',
                },
            ],
            {
                mainClass: 'fancybox-reset',
                closeButton: false,
            }
        );
    }

    setResultTitle(value) {
        if (!this.resultTitle) {
            return;
        }
        this.resultTitle.textContent = value;
    }

    setResultText(value) {
        if (!this.resultText) {
            return;
        }
        this.resultText.textContent = value;
    }
}
