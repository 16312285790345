export class RequestParams extends URLSearchParams {
    constructor(options) {
        super(options);
    }

    static instance = null;

    static getInstance() {
        if (!RequestParams.instance) {
            RequestParams.instance = new RequestParams();
        }
        return RequestParams.instance;
    }
}
