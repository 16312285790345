import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/src/ScrollToPlugin';
import {ScrollTrigger} from 'gsap/src/ScrollTrigger';

export class HorizontalScroll {
    constructor(options) {
        gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
        this.scrolledSection = document.querySelector(options.selectors.scrolledSection);
        this.panels = document.querySelectorAll(options.selectors.scrolledSection + '> *');
        this.init();
    }

    init() {
        this.tween = gsap.to(this.panels, {
            x: () => -1 * (this.scrolledSection.scrollWidth - innerWidth),
            ease: 'none',
            scrollTrigger: {
                trigger: '.main-creating-wrap',
                pin: true,
                start: 'top top',
                scrub: 1,
                end: () => '+=' + (this.scrolledSection.scrollWidth - innerWidth),
            },
        });
    }
}
