import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/src/ScrollTrigger';

export class AboutNumbersAnimation {
    constructor() {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.normalizeScroll({
            type: 'touch',
        });

        this.namesCells = document.querySelectorAll('.about-numbers__table-cell--name');
        this.values = document.querySelectorAll('.about-numbers__value');
        this.names = document.querySelectorAll('.about-numbers__name');

        this.pictureWrap = document.querySelector('.about-numbers__picture-wrap');
        this.playground = document.querySelector('.about-numbers__playground');
        this.pictureParts = document.querySelectorAll('.about-numbers__picture-part');
        this.picturePartBottomValues = ['0%', '52%', '20%'];

        this.init();
    }

    init() {
        this.isMobile = window.innerWidth <= 1023;
        this.pictureWrapOptions = {
            side: this.isMobile ? 'bottom' : 'top',
            percent: this.isMobile ? '10%' : '50%',
            triggerStart: this.isMobile ? '80% bottom' : 'top 70%',
        };
        this.valuesScrollTriggerStart = this.isMobile ? 'top bottom' : 'bottom bottom';

        this.onWindowResize();
        window.addEventListener('resize', () => {
            this.onWindowResize();
        });
        this.namesCells.forEach((name, i) => {
            if (this.values[i]) {
                gsap.to(this.values[i], {
                    y: 0,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: name,
                        start: this.valuesScrollTriggerStart,
                        scrub: 1,
                        end: () => '+=' + (this.names[i].scrollHeight - innerHeight),
                    },
                });
            }
            if (this.names[i] && this.isMobile) {
                gsap.to(this.names[i], {
                    y: 0,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: name,
                        start: 'top bottom',
                        scrub: 1,
                        end: () => '+=' + (this.names[i].scrollHeight - innerHeight),
                    },
                });
            }
            gsap.fromTo(
                this.pictureParts[i],
                {
                    bottom: '100vh',
                },
                {
                    bottom: this.picturePartBottomValues[i],
                    opacity: 1,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: name,
                        start: this.pictureWrapOptions.triggerStart,
                        scrub: 0,
                        end: () => '+=' + (this.names[i].scrollHeight - innerHeight),
                    },
                }
            );
        });
    }

    onWindowResize() {
        this.pictureWrap.style.setProperty('height', this.pictureParts[0].scrollHeight + 'px');
        this.playground.style.setProperty('height', this.pictureParts[0].scrollHeight + 'px');
    }
}
