/* global BX:writable */

import {RequestParams} from './RequestParams';

export class PageNavigation {
    constructor(options) {
        this.elementSelector = options.selectors.elementSelector;
        this.activePaginationItemSelector =
            options.selectors.activePaginationItemSelector ?? '[data-page-number].pagination__item--active';
        this.containerSelector = options.selectors.containerSelector;
        this.triggerSelector = options.selectors.triggerSelector ?? '[data-page-number]';
        this.paginationSelector = options.selectors.paginationSelector ?? '.pagination';
        this.fallbackTextSelector = options.selectors.fallbackTextSelector ?? '.fallback-text';
        this.apiUrl = options.apiUrl;
        this.requestParams = RequestParams.getInstance();
        this.DOMParser = new DOMParser();
        this.slider = options.slider;
        this.init();
    }

    init() {
        this.isPending = false;

        this.boundReplaceContent = this.replaceContent.bind(this);
        this.boundOnTriggerClick = this.onTriggerClick.bind(this);

        this.container = document.querySelector(this.containerSelector);
        this.currentPage = document.querySelector(this.activePaginationItemSelector)?.dataset?.pageNumber ?? '1';
        this.pagination = document.querySelector(this.paginationSelector);

        this.addClickEventForTriggers();
    }

    addClickEventForTriggers() {
        this.triggers = document.querySelectorAll(this.triggerSelector);
        this.triggers.forEach((trigger) => {
            trigger.addEventListener('click', this.boundOnTriggerClick);
        });
    }

    removeClickEventFromTriggers() {
        this.triggers.forEach((trigger) => {
            trigger.removeEventListener('click', this.boundOnTriggerClick);
        });
    }

    onTriggerClick(evt) {
        if (this.isPending) {
            return;
        }
        const target = evt.target.closest(this.triggerSelector);
        if (this.currentPage === target.dataset.pageNumber) {
            return;
        }
        this.currentPage = target.dataset.pageNumber;
        this.requestParams.set('PAGEN_1', this.currentPage);
        this.sendRequest().then(this.boundReplaceContent);
    }

    async sendRequest() {
        this.removeClickEventFromTriggers();
        this.isPending = true;
        const res = await fetch(this.apiUrl + '?' + this.requestParams, {
            headers: {
                'Session-Id': BX.bitrix_sessid(),
            },
        });
        return res.text();
    }

    replaceContent(data) {
        const dom = this.DOMParser.parseFromString(data, 'text/html');
        const elements = dom.querySelectorAll(this.elementSelector);
        const pagination = dom.querySelector(this.paginationSelector);
        const fallbackText = dom.querySelector(this.fallbackTextSelector);
        this.replacePagination(pagination.innerHTML);
        this.container.innerHTML = '';
        elements.forEach((element) => {
            this.container.appendChild(element);
        });
        if (!elements.length && fallbackText) {
            this.container.appendChild(fallbackText);
        }
        this.isPending = false;
        window.history.replaceState(
            null,
            '',
            window.location.origin + window.location.pathname + '?' + this.requestParams
        );
        if (this.slider) {
            this.slider.init();
        }
        window.scrollTo({
            top: 0,
        });
    }

    replacePagination(data) {
        this.pagination.innerHTML = data;
        this.currentPage = document.querySelector(this.activePaginationItemSelector)?.dataset?.pageNumber;
        this.addClickEventForTriggers();
    }
}
