export class IdeaSwitch {
    constructor(options) {
        this.projects = document.querySelector(options.selectors.projects);
        this.ideaSwitchWrapper = document.querySelector(options.selectors.ideaSwitchWrapper);
        this.checkbox = document.querySelector(options.selectors.checkbox);
        this.instruction = document.querySelector(options.selectors.instruction);
        this.init();
    }

    init() {
        this.boundOnCheckboxInput = this.onCheckboxInput.bind(this);
        this.checkbox.addEventListener('input', this.boundOnCheckboxInput);
    }

    onCheckboxInput() {
        this.projects.classList.toggle('active', this.checkbox.checked);
        this.ideaSwitchWrapper.classList.toggle('active', this.checkbox.checked);
        this.instruction.classList.toggle('active', !this.checkbox.checked);
    }
}
