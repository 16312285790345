import Swiper from 'swiper';

export class SwiperSlider {
    constructor(selector, options = null) {
        this.selector = selector;
        this.options = options;
        this.init();
    }

    init() {
        if (this.swiper && this.swiper.destroy) {
            this.swiper.destroy(true, true);
        }
        this.swiper = null;
        this.el = document.querySelector(this.selector);
        if (!this.el) {
            return;
        }
        this.swiper = new Swiper(this.selector, this.options);
        if (this.swiper instanceof Array) {
            return;
        }
        this.swiper.on('resize', (instance) => {
            this.onResize(instance);
        });
        this.onResize(this.swiper);
    }

    onResize(instance) {
        this.el.classList.toggle('centered-slides', instance.virtualSize <= instance.size);
    }
}
