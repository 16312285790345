export class HiddenInput {
    constructor(options) {
        this.triggers = document.querySelectorAll(options.selectors.trigger);
        this.el = document.querySelector(options.selectors.el);
        this.attribute = options.attribute;
        this.init();
    }

    init() {
        this.triggers.forEach((trigger, key) => {
            trigger.addEventListener('click', () => {
                this.el.value = this.triggers[key].dataset[this.attribute];
            });
        });
    }
}
