export class MainMenu {
    constructor(
        openSelector = '[data-entity="open-main-menu"]',
        closeSelector = '[data-entity="close-main-menu"]',
        menuSelector = '#main-menu'
    ) {
        this.openers = document.querySelectorAll(openSelector);
        this.closers = document.querySelectorAll(closeSelector);
        this.node = document.querySelector(menuSelector);
        this.isOpen = false;
        this.init();
    }

    init() {
        this.openers.forEach((opener) => {
            opener.addEventListener('click', () => {
                this.open();
            });
        });
        this.closers.forEach((closer) => {
            closer.addEventListener('click', () => {
                this.close();
            });
        });
        document.addEventListener('keyup', (evt) => {
            this.onDocumentKeyUp(evt);
        });
    }

    open() {
        if (this.isOpen) {
            return this;
        }
        this.node.classList.add('is-open');
        this.node.setAttribute('aria-hidden', 'false');
        document.documentElement.classList.add('scroll-lock');
        this.isOpen = true;
        return this;
    }

    close() {
        if (!this.isOpen) {
            return this;
        }
        this.node.classList.remove('is-open');
        this.node.setAttribute('aria-hidden', 'true');
        document.documentElement.classList.remove('scroll-lock');
        this.isOpen = false;
        return this;
    }

    onDocumentKeyUp(evt) {
        if (evt.keyCode !== 27 || !this.isOpen) {
            return this;
        }
        this.close();
        return this;
    }
}
