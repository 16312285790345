/* global BX:writable */

import {RequestParams} from './RequestParams';

export class Filters {
    constructor(options) {
        this.elementSelector = options.selectors.elementSelector;
        this.itemSelector = options.selectors.itemSelector;
        this.checkboxSelector = options.selectors.checkboxSelector;
        this.paginationSelector = options.selectors.paginationSelector ?? '.pagination';
        this.containerSelector = options.selectors.containerSelector;
        this.apiUrl = options.apiUrl;
        this.paramName = options.paramName;
        this.requestParams = RequestParams.getInstance();
        this.pagination = options.pagination;
        this.timer = null;
        this.init();
    }

    init() {
        this.boundOnCheckboxInput = this.onCheckboxInput.bind(this);
        this.boundReplaceContent = this.pagination.replaceContent.bind(this.pagination);

        this.pagination = document.querySelector(this.paginationSelector);
        this.container = document.querySelector(this.containerSelector);
        this.checkboxes = document.querySelectorAll(this.checkboxSelector);
        this.checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('input', this.boundOnCheckboxInput);
        });
    }

    onCheckboxInput(evt) {
        const checkbox = evt.target;
        checkbox.closest(this.itemSelector).classList.toggle('filters__item--selected');
        checkbox.checked
            ? this.requestParams.append(this.paramName, checkbox.value)
            : this.requestParams.delete(this.paramName, checkbox.value);
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.sendRequest().then(this.boundReplaceContent);
        }, 500);
    }

    async sendRequest() {
        this.requestParams.set('PAGEN_1', '1');
        const res = await fetch(this.apiUrl + '?' + this.requestParams, {
            headers: {
                'Session-Id': BX.bitrix_sessid(),
            },
        });
        return res.text();
    }
}
