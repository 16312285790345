export class Header {
    constructor(headerSelector = '#header', headerMockSelector = '.header-mock') {
        this.header = document.querySelector(headerSelector);
        this.headerMocks = document.querySelectorAll(headerMockSelector);
        this.init();
    }

    init() {
        document.addEventListener('scroll', () => {
            this.onDocumentScroll();
        });
        window.addEventListener('resize', () => {
            this.onWindowResize();
        });
    }

    onDocumentScroll() {
        if (!this.header.classList.contains('header--changing')) {
            return this;
        }
        if (window.scrollY === 0) {
            this.switchToTransparent();
            return this;
        }
        this.switchToFilled();
        return this;
    }

    switchToTransparent() {
        this.header.classList.add('header--transparent');
    }

    switchToFilled() {
        this.header.classList.remove('header--transparent');
    }

    onWindowResize() {
        if (!this.headerMocks.length) {
            return this;
        }
        this.headerMocks.forEach((mock) => {
            mock.style.height = this.header.offsetHeight + 'px';
        });
        return this;
    }
}
