import {Navigation, Pagination} from 'swiper/modules';
import {Fancybox} from '@fancyapps/ui';
import {Form} from '../../classes/form/Form';
import {HiddenInput} from '../../classes/form/HiddenInput';
import {Header} from '../../classes/header/Header';
import {PhoneNumberMask} from '../../classes/form/PhoneNumberMask';
import {AboutNumbersAnimation} from '../../classes/AboutNumbersAnimation';
import {Widget} from '../../classes/Widget';
import {SwiperSlider} from '../../classes/SwiperSlider';
import {MainMenu} from '../../classes/header/MainMenu';
import {ContactsMap} from '../../classes/ContactsMap';
import {HorizontalScroll} from '../../classes/HorizontalScroll';
import {Search} from '../../classes/header/Search';
import {PageNavigation} from '../../classes/filter/PageNavigation';
import {Filters} from '../../classes/filter/Filters';
import {IdeaSwitch} from '../../classes/IdeaSwitch';

document.addEventListener('DOMContentLoaded', async () => {
    const header = new Header();
    header.onDocumentScroll().onWindowResize();

    new PhoneNumberMask();

    if (document.querySelector('.about-numbers')) {
        new AboutNumbersAnimation();
    }

    if (document.querySelector('#widget-control')) {
        new Widget();
    }

    if (document.querySelector('form[name="designers"]')) {
        new Form({
            selectors: {
                form: 'form[name="designers"]',
                result: '#fancybox-result',
                resultTitle: '.modal__title',
                resultText: '.modal__text',
            },
        });
    }

    if (document.querySelector('form[name="product"]')) {
        new Form({
            selectors: {
                form: 'form[name="product"]',
                result: '#fancybox-result',
                resultTitle: '.modal__title',
                resultText: '.modal__text',
            },
        });
    }

    if (document.querySelector('form[name="service"]')) {
        new Form({
            selectors: {
                form: 'form[name="service"]',
                result: '#fancybox-result',
                resultTitle: '.modal__title',
                resultText: '.modal__text',
            },
        });
        new HiddenInput({
            selectors: {
                trigger: '[data-entity="open-service-form"]',
                el: 'form[name="service"] input[type="hidden"][name="element_id"]',
            },
            attribute: 'serviceId',
        });
    }

    Fancybox.bind('[data-fancybox="application-modal"]', {
        closeButton: false,
        mainClass: 'fancybox-reset',
        groupAttr: false,
    });

    new SwiperSlider('.main-reviews-slider', {
        modules: [Navigation],
        slidesPerView: 1.08,
        spaceBetween: 8,
        breakpoints: {
            540: {
                slidesPerView: 1.7,
            },
            768: {
                slidesPerView: 2.2,
            },
            1024: {
                slidesPerView: 2.7,
            },
            1280: {
                slidesPerView: 'auto',
            },
        },
        navigation: {
            nextEl: '.main-reviews-slider__nav-btn--next',
            prevEl: '.main-reviews-slider__nav-btn--prev',
        },
    });

    new MainMenu();

    const projectPhotosSlider = new SwiperSlider('.project-photos', {
        modules: [Pagination],
        slidesPerView: 1,
        loop: true,
        pagination: {
            bulletClass: 'project-photos__bullet',
            bulletActiveClass: 'project-photos__bullet--active',
            el: '.project-photos__pagination',
            lockClass: 'project-photos__pagination--lock',
            clickable: true,
        },
    });

    new SwiperSlider('.work-slider', {
        slidesPerView: 1.8,
        spaceBetween: 12,
        breakpoints: {
            540: {
                slidesPerView: 2.3,
            },
            640: {
                slidesPerView: 2.6,
            },
            768: {
                spaceBetween: 24,
                slidesPerView: 3.2,
            },
            1024: {
                slidesPerView: 'auto',
            },
        },
    });

    new SwiperSlider('.partners-slider', {
        slidesPerView: 1.8,
        spaceBetween: 12,
        breakpoints: {
            640: {
                slidesPerView: 2.6,
            },
            768: {
                spaceBetween: 24,
                slidesPerView: 'auto',
            },
        },
    });

    if (document.querySelector('#contacts-map')) {
        new ContactsMap(
            '#contacts-map',
            {
                location: {
                    center: [135.049111, 48.490858],
                    zoom: 16,
                },
                behaviors: ['drag', 'pinchZoom', 'dblClick'],
            },
            {
                className: 'contacts__map-marker',
                src: '/local/templates/hakis/assets/src/images/geo/geo.svg',
                coordinates: [135.052452, 48.491917],
            }
        );
    }

    if (document.querySelector('.main-creating')) {
        new HorizontalScroll({
            selectors: {
                scrolledSection: '.main-creating',
            },
        });
    }

    if (document.querySelector('.search-result')) {
        new Search({
            apiUrl: '/api/v1/search/',
            selectors: {
                searchResultElement: '.search-result',
                input: 'input[name="q"]',
                node: '.search',
                opener: '[data-entity="open-search"]',
                closer: '[data-entity="close-search"]',
                overlay: '.overlay',
            },
        });
    }

    new SwiperSlider('.about-gallery__slider', {
        spaceBetween: 12,
        slidesPerView: 1.05,
        breakpoints: {
            640: {
                spaceBetween: 24,
                slidesPerView: 1.5,
            },
        },
        centeredSlides: true,
        initialSlide: 1,
    });

    Fancybox.bind('[data-fancybox="about-gallery"]', {
        Thumbs: false,
        Toolbar: {
            display: {
                right: ['close'],
            },
        },
        l10n: {
            CLOSE: 'Закрыть',
            PREV: 'Назад',
            NEXT: 'Далее',
        },
    });

    if (document.querySelector('#projects-list')) {
        const pagination = new PageNavigation({
            apiUrl: '/api/v1/projects/',
            selectors: {
                elementSelector: '.project',
                containerSelector: '#projects-list',
            },
            slider: projectPhotosSlider,
        });

        new Filters({
            apiUrl: '/api/v1/projects/',
            selectors: {
                elementSelector: '.project',
                itemSelector: '.filters__item',
                checkboxSelector: 'input[type="checkbox"][name="section_id[]"]',
                containerSelector: '#projects-list',
            },
            paramName: 'section_id[]',
            pagination: pagination,
        });
    }

    if (document.querySelector('#projects-ideas-list')) {
        const pagination = new PageNavigation({
            apiUrl: '/api/v1/projects-ideas/',
            selectors: {
                elementSelector: '.project',
                containerSelector: '#projects-ideas-list',
            },
            slider: projectPhotosSlider,
        });

        new Filters({
            apiUrl: '/api/v1/projects-ideas/',
            selectors: {
                elementSelector: '.project',
                itemSelector: '.filters__item',
                checkboxSelector: 'input[type="checkbox"][name="section_id[]"]',
                containerSelector: '#projects-ideas-list',
            },
            paramName: 'section_id[]',
            pagination: pagination,
        });
    }

    if (document.querySelector('.products')) {
        const pagination = new PageNavigation({
            apiUrl: '/api/v1/products/',
            selectors: {
                elementSelector: '.products-card',
                containerSelector: '.products',
            },
        });

        new Filters({
            apiUrl: '/api/v1/products/',
            selectors: {
                elementSelector: '.products-card',
                itemSelector: '.filters__item',
                checkboxSelector: 'input[type="checkbox"][name="section_id[]"]',
                containerSelector: '.products',
            },
            paramName: 'section_id[]',
            pagination: pagination,
        });
    }

    if (document.querySelector('.services-list')) {
        new PageNavigation({
            apiUrl: '/api/v1/services/',
            selectors: {
                elementSelector: '.service-card',
                containerSelector: '.services-list',
            },
        });
    }

    if (document.querySelector('input[type="checkbox"][name="show_ideas"]')) {
        new IdeaSwitch({
            selectors: {
                projects: '#instruction-projects',
                ideaSwitchWrapper: '.idea-switch',
                checkbox: 'input[type="checkbox"][name="show_ideas"]',
                instruction: '.instruction',
            },
        });
    }

    new SwiperSlider('.product-detail__photos-slider', {
        modules: [Navigation, Pagination],
        spaceBetween: 24,
        slidesPerView: 1,
        navigation: {
            nextEl: '.product-detail__slider-nav--right',
            prevEl: '.product-detail__slider-nav--left',
            disabledClass: 'product-detail__slider-nav--disabled',
        },
        pagination: {
            bulletClass: 'product-detail__slider-bullet',
            bulletActiveClass: 'product-detail__slider-bullet--active',
            el: '.product-detail__slider-pagination',
            lockClass: 'product-detail__slider-pagination--lock',
            clickable: true,
        },
    });

    Fancybox.bind('[data-fancybox="product-photo"]', {
        Thumbs: false,
        Toolbar: {
            display: {
                right: ['close'],
            },
        },
        l10n: {
            CLOSE: 'Закрыть',
            PREV: 'Назад',
            NEXT: 'Далее',
        },
    });

    new SwiperSlider('.product-usage__slider', {
        spaceBetween: 24,
        slidesPerView: 1,
        breakpoints: {
            1024: {
                slidesPerView: 'auto',
            },
            768: {
                slidesPerView: 1.5,
            },
        },
    });

    new SwiperSlider('.product-services__slider', {
        spaceBetween: 24,
    });
});
