export class Widget {
    constructor(options) {
        this.elSelector = options?.selectors?.el ?? '#widget-control';
        this.el = document.querySelector(this.elSelector);
        this.text = document.querySelector(options?.selectors?.expandWrap ?? '.widget__text');
        this.isOpen = false;
        this.init();
    }

    init() {
        this.close();
        document.addEventListener('click', this.onDocumentClick.bind(this));
    }

    onDocumentClick(evt) {
        if (evt.target.closest(this.elSelector) && !this.isOpen) {
            this.open();
            return;
        }
        this.close();
    }

    open() {
        this.isOpen = true;
        this.el.setAttribute('title', 'Закрыть виджет');
        this.toggle();
    }

    close() {
        this.isOpen = false;
        this.el.setAttribute('title', 'Открыть виджет');
        this.toggle();
    }

    toggle() {
        this.el.classList.toggle('active', this.isOpen);
        // this.text.classList.toggle('visually-hidden', this.isOpen);
    }
}
